import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import { VerticalDivider } from '../../../components/Divider';
import List from '../../../components/List';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif } from '../../../components/Typography';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  FullWidthTherapySection,
  MobileTherapyContainer,
  MobileTherapySection,
  TherapyContainer,
  TherapySection,
} from './styles';
import { MobileImageData } from '../../../types/types';
import { motion } from 'framer-motion';

const diseases = [
  '근육 이상에 의한 요통',
  '목/허리디스크',
  '척추관절 가동범위 축소',
  '일자목 / 거북목',
  '만성적 근육 긴장',
  '교통사고후유증',
  '편두통',
  '척추측만증',
];

const therapyDescription = [
  '환자 개개인의 체형과 자세에 따른 맞춤치료를 진행합니다.',
  '신경계의 기능을 향상시켜 몸의 자연 치유 과정을 도와줍니다.',
  '목, 허리, 골반 등 척추 전체의 불균형을 해소합니다.',
];

const mobileImageList: MobileImageData[] = [
  {
    arr: ['1.svg'],
    style: {
      padding: '0',
    },
  },
  {
    arr: ['6-1.png', '6-2.svg', '6-3.png', '6-4.svg', '6-5.png', '6-6.svg', '6-7.png', '6-8.svg'],
  },
  {
    arr: ['2-1.svg', '2-2.png', '2-3.svg', '2-4.png'],
    style: {
      // paddingBottom: '60px',
      background: '#222222',
    },
  },
  {
    arr: ['3-1.svg', '3-2.png', '3-3.svg'],
  },
  {
    arr: ['4-1.svg', '4-2.svg'],
  },
  {
    arr: ['5-1.svg', '5-2.svg'],
  },
];

const SpineTherapyPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 추나요법</title>
        </Helmet>
        <PageTitle title="추나요법" bgImage="spine" />
        <MobileTherapyContainer>
          {mobileImageList.map((item, index) => (
            <MobileTherapySection key={index} style={item.style}>
              {item.arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/spine/therapy/${image}`}
                  alt=""
                />
              ))}
            </MobileTherapySection>
          ))}
        </MobileTherapyContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>에스본한의원 | 추나요법</title>
      </Helmet>
      <PageTitle title="추나요법" bgImage="spine" />
      <TherapyContainer>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            ease: 'easeInOut',
            duration: 1.3,
            y: {
              duration: 0.3,
            },
          }}
          viewport={{
            once: true,
          }}
        >
          <TherapySection>
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={
                <>
                  치료를 받아도 그때 뿐
                  <br />
                  같은 부위에 통증이 재발한다면
                  <br />
                  체형의 문제일 수 있습니다
                </>
              }
              position="center"
            />
            <Namsan
              style={{
                display: 'block',
                marginTop: '30px',
                color: '#d9d9d9',
                textAlign: 'center',
                lineHeight: '33px',
              }}
            >
              신체의 균형이 어긋난 상태로 오랜 기간 방치하면 <br />
              만성통증으로 발전할 수 있습니다
            </Namsan>
            <VerticalDivider
              style={{
                height: '81px',
                width: '1px',
                marginTop: '55px',
                marginBottom: '55px',
              }}
            />
            <Namsan
              size="large"
              style={{
                textAlign: 'center',
                lineHeight: '36px',
              }}
            >
              추나요법을 통해 어긋난 부위를
              <br />
              <Namsan as="strong" size="large" style={{
                  color: '#D2A780'
                }}
              >
                교정하여 체형 불균형과 통증을 해소
              </Namsan>
              합니다
            </Namsan>
            <div
              style={{
                width: '100%',
                backgroundColor: '#191919',
                padding: '68px 123px',
                marginTop: '50px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <List
                style={{
                  gridRowGap: '20px',
                }}
              >
                {diseases.map((disease) => (
                  <li key={disease}>
                    <img
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                      }}
                      src="/icons/check.svg"
                      alt=""
                    />
                    <Namsan size="large">{disease}</Namsan>
                  </li>
                ))}
              </List>
            </div>
          </TherapySection>
        </motion.div>



{/* 여기 */}
<FullWidthTherapySection
  style={{
    height: 'auto',
  }}
  color="dark"
>
  <TherapySection 
    style={{ 
      flexDirection: 'row', 
      alignItems: 'center',
      gap: '20px'
    }}
  >
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //flex: 1,
      }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1.3,
        y: {
          duration: 0.3,
        },
      }}
      viewport={{
        once: true,
      }}
    >
      <img width={'580px'} height="465px" src="/images/spine/therapy/before1.png" alt="치료전" />
      {/* 치료 전 텍스트 추가 */}
      <NotoSerif
        style={{
        display: 'block',
        color: '#D2A780',
        paddingTop: '30px',
        textAlign: 'center',
      }}
        size="large"
      >
        치료전
        </NotoSerif>
    </motion.div>
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //flex: 1,
      }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1.3,
        y: {
          duration: 0.3,
        },
      }}
      viewport={{
        once: true,
      }}
    >
      <img width={'580px'} height="465px" src="/images/spine/therapy/after1.png" alt="치료후" />
      {/* 치료 전 텍스트 추가 */}
      <NotoSerif
        style={{
        display: 'block',
        color: '#D2A780',
        paddingTop: '30px',
        textAlign: 'center',
      }}
        size="large"
      >
        추나치료 10회 후
        </NotoSerif>
    </motion.div>
  </TherapySection>

  {/* 두 번째 행 */}
  <TherapySection 
    style={{ 
      flexDirection: 'row', 
      alignItems: 'center',
      gap: '20px',
      marginTop: '100px',
    }}
  >
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //flex: 1,
      }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1.3,
        y: {
          duration: 0.3,
        },
      }}
      viewport={{
        once: true,
      }}
    >
      <img width={'580px'} height="465px" src="/images/spine/therapy/before2.png" alt="치료전" />
      {/* 치료 전 텍스트 추가 */}
      <NotoSerif
        style={{
        display: 'block',
        color: '#D2A780',
        paddingTop: '30px',
        textAlign: 'center',
      }}
        size="large"
      >
        치료전
        </NotoSerif>
    </motion.div>
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        //flex: 1,
      }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        ease: 'easeInOut',
        duration: 1.3,
        y: {
          duration: 0.3,
        },
      }}
      viewport={{
        once: true,
      }}
    >
      <img width={'580px'} height="465px" src="/images/spine/therapy/after2.png" alt="치료후" />
      {/* 치료 전 텍스트 추가 s */}
      <NotoSerif
        style={{
        display: 'block',
        color: '#D2A780',
        paddingTop: '30px',
        textAlign: 'center',
      }}
        size="large"
      >
        추나치료 10회 후
        </NotoSerif>
    </motion.div>
  </TherapySection>
    </FullWidthTherapySection>

        
        
{/* 여기 */}
        <FullWidthTherapySection
          style={{
            height: 'auto',
            // paddingTop: '140px',
            // paddingBottom: '140px',
          }}
          color="dark"
        >
          <TherapySection
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <Card
                titleStyle={{
                  color: '#d9d9d9',
                }}
                paragraphStyle={{
                  color: '#D2A780',
                }}
                title="365일 척추관절 중점진료 에스본한의원"
                paragraph={'추나요법이란?'}
              />
              <div
                style={{
                  lineHeight: '33px',
                  marginTop: '50px',
                }}
              >
                <Namsan
                  style={{
                    color: '#a9a9a9',
                  }}
                >
                  비정상적으로 틀어진 뼈와 근육을 정상적으로 복원하여 통증을
                  <br />
                  완화하고 척추와 주변 조직의 기능을 개선하는 치료법입니다.
                </Namsan>
                <NotoSerif
                  style={{
                    display: 'block',
                    color: '#D2A780',
                    marginTop: '50px',
                  }}
                  size="large"
                >
                  *에스본한의원의 추나요법 특징
                </NotoSerif>
                <List
                  style={{
                    gridTemplateColumns: '1fr',
                    marginTop: '20px',
                    marginBottom: '15px',
                  }}
                >
                  {therapyDescription.map((therapy) => (
                    <li key={therapy}>
                      <img
                        style={{
                          width: '25px',
                          height: '25px',
                          display: 'block',
                          marginRight: '10px',
                        }}
                        src="/icons/check-outlined.svg"
                        alt=""
                      />
                      <Namsan
                        style={{
                          color: '#ffffff',
                        }}
                      >
                        {therapy}
                      </Namsan>
                    </li>
                  ))}
                </List>
                <Namsan
                  style={{
                    color: '#a9a9a9',
                    display: 'block',
                  }}
                  size="small"
                >
                  같은 질환, 비슷한 증통 및 저림 증세라 하여도 증상과 통증이 어디에서
                </Namsan>
                <Namsan
                  style={{
                    color: '#a9a9a9',
                    display: 'block',
                  }}
                  size="small"
                >
                  비롯되느냐에 따라 추나 치료를 하는 위치, 추나요법의 기술이 모두 달라집니다.
                </Namsan>
              </div>
            </motion.div>
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: '50%',
                gap: '20px',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                delay: 0.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <img width={'580px'} height="400px" src="/images/spine/therapy/image1.png" alt="" />
              <img width={'580px'} height="400px" src="/images/spine/therapy/image2.png" alt="" />
            </motion.div>
          </TherapySection>
        </FullWidthTherapySection>
        <FullWidthTherapySection
          style={{
            height: 'auto',
            padding: 0,
          }}
          color="dark"
        >
          <TherapySection
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flex: 1,
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <Card
                titleStyle={{
                  color: '#A9A9A9',
                }}
                title="365일 척추관절 중점진료 에스본한의원"
                paragraph={
                  <>
                    추나요법 시 발생하는
                    <br />
                    뼈소리의 진실
                  </>
                }
              />
              <div
                style={{
                  lineHeight: '33px',
                  marginTop: '60px',
                }}
              >
                <Card
                  containerStyle={{
                    flexDirection: 'column-reverse',
                    gap: '20px',
                    marginBottom: '30px',
                  }}
                  titleAs="p"
                  titleStyle={{
                    color: '#d9d9d9',
                  }}
                  title={
                    <>
                      뼈에 붙은 관절은 진공상태를 유지하고 있습니다.
                      <br />
                      수기로 빠르게 관절을 움직이면 내부에 공기가 차게 되며,
                      <br />
                      순간적으로 공기가 빠져나가면서 우두둑 소리가 발생합니다.
                      <br />
                      자연스러운 현상이기 때문에 크게 걱정하지 않으셔도 됩니다.
                    </>
                  }
                  paragraphStyle={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '300',
                  }}
                  paragraph="우두둑, 뼈소리가 나는 이유 "
                  paragraphAs="h3"
                />
                <Card
                  containerStyle={{
                    flexDirection: 'column-reverse',
                    gap: '20px',
                  }}
                  titleAs="p"
                  titleStyle={{
                    color: '#d9d9d9',
                  }}
                  title={
                    <>
                      추나요법은 어긋난 신체 구조물을 본래의 위치로 재정렬하는
                      <br />
                      치료이기 때문에 뼈에서 나는 소리의 유무는 중요하지 않습니다.
                    </>
                  }
                  paragraphStyle={{
                    color: '#D2A780',
                    fontSize: '28px',
                    fontWeight: '300',
                  }}
                  paragraph="소리가 안 나면 효과가 없나요?"
                  paragraphAs="h3"
                />
              </div>
            </motion.div>
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: '50%',
                gap: '20px',
              }}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                ease: 'easeInOut',
                duration: 1.3,
                delay: 0.3,
                y: {
                  duration: 0.3,
                },
              }}
              viewport={{
                once: true,
              }}
            >
              <img width={'580px'} height="700px" src="/images/spine/therapy/image3.png" alt="" />
            </motion.div>
          </TherapySection>
        </FullWidthTherapySection>
        <TherapySection
          style={{
            gap: '50px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={'추나요법 만족도 92.8%'}
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              maxWidth: '980px',
              width: '100%',
              aspectRatio: '980 / 500',
              backgroundImage: 'url(/images/spine/therapy/image4.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src="/images/spine/therapy/image4.png"
              alt="추나요법의 전반적인 만족도"
            />
          </motion.div>
        </TherapySection>

        <TherapySection
          style={{
            gap: '50px',
            width: '100%',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={'추나요법 건강보험 적용'}
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              maxWidth: '980px',
              width: '100%',
              aspectRatio: '980 / 500',
              backgroundImage: 'url(/images/spine/therapy/image5.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                display: 'none',
              }}
              src="/images/spine/therapy/image5.png"
              alt="추나요법 건강보험 적용"
            />
          </motion.div>
        </TherapySection>
      </TherapyContainer>
    </>
  );
};

export default SpineTherapyPage;
