import {
  FullWidthHomeSection,
  FullWidthImageDescription,
  HomeContainer,
  HomeDifferenceSection,
  HomeFaqCheckIcon,
  HomeFaqContainer,
  HomeFaqListItem,
  HomeFaqListItemHeader,
  HomeGradientSection,
  HomeMobileContainer,
  HomePainSwiperIndicator,
  HomeSection,
  HomeTableRow,
  HomeYoutubeWrapper,
  ThumbImage,
  ThumbSlide,
} from './styles';
import Button from '../../components/Button';
import { Namsan, NotoSerif, Pretendard, Roboto } from '../../components/Typography';
import Card from '../../components/Card';
import Description from '../../components/Description';
import Map from '../../components/Map';
import Accordion from '../../components/Accordion';
import { Navigation, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useRef, useState } from 'react';
import { cases, facilities, faq, mobileFaq } from './data';
import { Link } from 'react-router-dom';
import { reservationLink } from '../../utils/const';
import YouTube from 'react-youtube';
import useWindowSize from '../../hooks/useWindowSize';
import HomePainSwiper from './HomePainSwiper';
import { Helmet } from 'react-helmet';
import { MobileSection } from '../../components/common';
import {
  FaqCheckIcon,
  FaqContainer,
  FaqListItem,
  FaqListItemHeader,
} from '../../components/FaQ/styles';
import { motion } from 'framer-motion';

const HomePage = () => {
  const subjectsRef = useRef<SwiperRef>(null);
  const facilityRef = useRef<SwiperRef>(null);
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [mobileThumbsSwiper, setMobileThumbsSwiper] = useState<any>(null);
  const [currentFacilityIndex, setCurrentFacilityIndex] = useState<number>(0);
  const { width } = useWindowSize();

  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원</title>
        </Helmet>
        <HomeMobileContainer
          style={{
            gap: '150px',
          }}
        >
          <div>
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/1.svg`}
              alt=""
            />
          </div>
          <div
            style={{
              padding: '0 16px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/2-1.svg`}
              alt=""
            />
            <HomeYoutubeWrapper style={{
                marginTop: 0,
              }}
            >
              <YouTube
                videoId={'T0CjxSs_tOE'}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
                onEnd={(e: any) => {
                  e.target.stopVideo(0);
                }}
              />
            </HomeYoutubeWrapper>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                width: '100%',
                maxWidth: '980px',
                justifyContent: 'center',
                alignSelf: 'center',
                // paddingLeft: '50px',
                // paddingRight: '50px',
              }}
            >
              <div>
                {/* <img
                  width={'100%'}
                  src="/images/home/prize1.svg"
                  alt="첩약 건강보험 시범사업 선정"
                /> */}
                <object type="image/svg+xml" data="/images/home/prize1.svg" width={'100%'}>첩약 건강보험 시범사업 선정</object>
              </div>
              <div>
                {/* <img width={'100%'} src="/images/home/prize2.svg" alt="산재보험 지정 한의원" /> */}
                <object type="image/svg+xml" data="/images/home/prize2.svg" width={'100%'}>산재보험 지정 한의원</object>
              </div>
              <div>
                {/* <img width={'100%'} src="/images/home/prize3.svg" alt="자동차보험 지정 한의원" /> */}
                <object type="image/svg+xml" data="/images/home/prize3.svg" width={'100%'}>자동차보험 지정 한의원</object>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: '0 16px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/3-1.svg`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/3-2.jpg`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/3-3.svg`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/3-4.jpg`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/3-5.svg`}
              alt=""
            />
          </div>
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              style={{
                width: '70%',
                padding: '0 16px',
                // marginBottom: '30px',
              }}
            >
              <img
                src={`/responsive/home/4.svg`}
                // src={`/responsive/therapy/${index + 1}.svg`}
                alt=""
              />
            </div>
            {/* <HomePainSwiper
              style={{
                marginLeft: '16px',
              }}
            /> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <img
                src={`/responsive/home/graph/image1.svg`}
                style={{
                  width: '100%',
                }}
                // src={`/responsive/therapy/${index + 1}.svg`}
                alt=""
              />
              <img
                src={`/responsive/home/graph/image2.svg`}
                style={{
                  width: '100%',
                }}
                // src={`/responsive/therapy/${index + 1}.svg`}
                alt=""
              />
              <img
                src={`/responsive/home/graph/image3.svg`}
                style={{
                  width: '100%',
                }}
                // src={`/responsive/therapy/${index + 1}.svg`}
                alt=""
              />
            </div>
          </div>

          <div>
            <div
              style={{
                width: '70%',
                padding: '0 16px',
                // marginBottom: '40px',
              }}
            >
              <img
                src={`/responsive/home/5.svg`}
                // src={`/responsive/therapy/${index + 1}.svg`}
                alt=""
              />
            </div>
            <div
              style={{
                marginLeft: '16px',
                position: 'relative',
              }}
            >
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={20}
                // loop={true}
                style={{
                  // marginTop: '50px',
                }}
                ref={subjectsRef}
              >
                {cases.map(({ description, image, title }, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    <div
                      style={{
                        width: '243px',
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${image})`,
                          height: '100%',
                          aspectRatio: '243 / 300',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <NotoSerif
                          style={{
                            marginTop: '20px',
                            marginBottom: '8px',
                            color: '#D2A780',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '30px',
                          }}
                        >
                          {title}
                        </NotoSerif>
                        <Namsan
                          style={{
                            lineHeight: '22.4px',
                            fontSize: '14px',
                            fontWeight: 300,
                          }}
                        >
                          {description}
                        </Namsan>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div>
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/6.svg`}
              alt=""
            />
          </div>

          <div>
            <div
              style={{
                width: '70%',
                padding: '0 16px',
                // marginBottom: '40px',
              }}
            >
              <img src={`/responsive/home/7.svg`} alt="" />
            </div>
            <div
              style={{
                position: 'relative',
              }}
            >
              <Swiper
                modules={[Thumbs, Navigation]}
                spaceBetween={10}
                ref={facilityRef}
                thumbs={{
                  swiper:
                    mobileThumbsSwiper && !mobileThumbsSwiper.destroyed ? mobileThumbsSwiper : null,
                }}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                navigation
                onSlideChange={(index: any) => {
                  setCurrentFacilityIndex(index.activeIndex);
                }}
              >
                {facilities.map((facility, index) => (
                  <SwiperSlide key={index}>
                    <ThumbImage src={facility} alt="" />
                  </SwiperSlide>
                ))}
              </Swiper>
              <HomePainSwiperIndicator>
                <Roboto>{(currentFacilityIndex + 1).toString().padStart(2, '0')}</Roboto>
                <Roboto> / {facilities.length.toString().padStart(2, '0')}</Roboto>
              </HomePainSwiperIndicator>
            </div>
            <div
              style={{
                width: '72%',
                padding: '0 16px',
                // margin: '40px 0 60px',
              }}
            >
              <img src={`/responsive/home/7-1.svg`} alt="" />
            </div>
            <div
              style={{
                width: '100%',
                paddingLeft: '16px',
              }}
            >
              <Swiper
                onSwiper={setMobileThumbsSwiper}
                spaceBetween={10}
                slidesPerView={2.2}
                breakpoints={{
                  500: {
                    slidesPerView: 'auto',
                  },
                }}
                modules={[Thumbs]}
              >
                {facilities.map((facility, index) => (
                  <ThumbSlide key={index}>
                    <ThumbImage src={facility} alt="" />
                  </ThumbSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div
            style={{
              padding: '0 16px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/8-1.svg`}
              alt=""
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/images/home/map.svg`}
              alt=""
            />
            <Map
              mapStyle={{
                boxShadow: 'none',
                aspectRatio: '368 / 250',
                maxWidth: 'unset',
                maxHeight: 'unset',
              }}
            />
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/8-3.svg`}
              alt=""
            />
          </div>
          <div
            style={{
              padding: '0 16px',
            }}
          >
            <img
              style={{
                width: '100%',
              }}
              src={`/responsive/home/9.svg`}
              // src={`/responsive/therapy/${index + 1}.svg`}
              alt=""
            />
            <FaqContainer
              style={{
                // marginTop: '40px',
              }}
            >
              {mobileFaq.map(({ question, answer, initialOpen }, index) => (
                <FaqListItem key={index}>
                  <Accordion
                    header={
                      <FaqListItemHeader>
                        <NotoSerif
                          style={{
                            flex: 1,
                            textWrap: 'nowrap',
                          }}
                        >
                          {question}
                        </NotoSerif>

                        <FaqCheckIcon>
                          <svg
                            width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.14258 4.57153L4.57103 0.999988L0.999488 4.57153"
                              stroke="#1B2E40"
                            />
                          </svg>
                        </FaqCheckIcon>
                      </FaqListItemHeader>
                    }
                    body={
                      <Namsan
                        as="p"
                        style={{
                          paddingLeft: '16px',
                          paddingBottom: '20px',
                          color: '#d9d9d9',
                          lineHeight: '22.4px',
                          fontSize: '14px',
                        }}
                      >
                        {answer}
                      </Namsan>
                    }
                    initialOpen={initialOpen}
                  />
                </FaqListItem>
              ))}
            </FaqContainer>
          </div>
        </HomeMobileContainer>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>에스본한의원</title>
      </Helmet>
      <HomeContainer>
        <HomeGradientSection>
          <motion.div
            style={{
              height: '100%',
              maxWidth: '1180px',
              margin: 'auto',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div
              style={{
                color: 'white',
              }}
            >
              <Namsan
                style={{
                  color: '#ffffff',
                  fontWeight: 700,
                }}
                as="h2"
              >
                365일 척추관절 중점진료 에스본한의원
              </Namsan>
              <NotoSerif
                style={{
                  lineHeight: '60px',
                  marginTop: '16px',
                  color: '#ffffff',
                }}
                as="p"
                size="xlarge"
              >
                치료를 받아도 문제를 파악하기 어려웠던 분들께
                <br />
              </NotoSerif>
              <NotoSerif
                style={{
                  lineHeight: '60px',
                  color: '#D2A780',
                  marginBottom: '48px',
                }}
                as="p"
                size="xlarge"
              >
                명쾌한 해답을 제시해 드리겠습니다
              </NotoSerif>
              <Namsan
                as="p"
                style={{
                  color: '#ffffff',
                  marginBottom: '96px',
                  lineHeight: '32px',
                }}
              >
                안산, 시흥, 안양 등 타지에서
                <br />
                찾아오는 환자가 많은 한의원
              </Namsan>
            </div>
            <div>
              <Button
                hasRightArrow={true}
                style={{
                  width: '178px',
                }}
              >
                <Link to={reservationLink} target="_blank">
                  <Namsan
                    style={{
                      fontSize: '18px',
                      color: '#ffffff',
                    }}
                  >
                    진료 예약하기
                  </Namsan>
                </Link>
              </Button>
            </div>
          </motion.div>
        </HomeGradientSection>
        <HomeSection
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              containerStyle={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph="에스본한의원을 만나보세요"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            viewport={{
              once: true,
            }}
          >
            <HomeYoutubeWrapper>
              <YouTube
                videoId={'T0CjxSs_tOE'}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
                onEnd={(e: any) => {
                  e.target.stopVideo(0);
                }}
              />
            </HomeYoutubeWrapper>
            <div
              style={{
                display: 'flex',
                gap: '40px',
                width: '100%',
                maxWidth: '980px',
                alignSelf: 'center',
                paddingLeft: '50px',
                paddingRight: '50px',
              }}
            >
              <div>
                {/* <img
                  width={'100%'}
                  src="/images/home/prize1.svg"
                  alt="첩약 건강보험 시범사업 선정"
                /> */}
                <object type="image/svg+xml" data="/images/home/prize1.svg" width={'100%'}>첩약 건강보험 시범사업 선정</object>
              </div>
              <div>
                {/* <img width={'100%'} src="/images/home/prize2.svg" alt="산재보험 지정 한의원" /> */}
                <object type="image/svg+xml" data="/images/home/prize2.svg" width={'100%'}>산재보험 지정 한의원</object>
              </div>
              <div>
                {/* <img width={'100%'} src="/images/home/prize3.svg" alt="자동차보험 지정 한의원" /> */}
                <object type="image/svg+xml" data="/images/home/prize3.svg" width={'100%'}>자동차보험 지정 한의원</object>
              </div>
            </div>
          </motion.div>
        </HomeSection>
        <HomeDifferenceSection
          style={{
            position: 'relative',
          }}
        >
          <motion.div
            style={{
              flexGrow: 1,
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              containerStyle={{
                position: 'sticky',
                top: '160px',
              }}
              title="에스본한의원 차별점"
              paragraph={
                <>
                  근골격계 치료를 위해
                  <br />
                  타지에서도 찾아오는
                  <br />
                  이유는,
                </>
              }
            />
          </motion.div>
          <motion.div
            style={{
              maxWidth: '680px',
              display: 'flex',
              flexDirection: 'column',
              gap: '100px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <div>
              <div>
                <img width={'100%'} alt="" src="/images/home/image1.png" />
              </div>
              <Description
                style={{
                  marginTop: '50px',
                }}
                title="초음파 기기를 활용하여 높인 호전율"
                paragraph={
                  <>
                    고해상도 초음파 기기를 사용하여 통증의 원인을 정확하게 진단하고
                    <br />
                    실시간으로 자입되는 침을 확인하면서 치료를 진행하기 때문에
                    <br />
                    높은 시술 정확도와 안전성을 기대할 수 있습니다
                  </>
                }
              />
            </div>
            <div>
              <div>
                <img width={'100%'} alt="" src="/images/home/image3.png" />
              </div>
              <Description
                style={{
                  marginTop: '50px',
                }}
                title="척추관절 특화 미네랄약침"
                paragraph={
                  <>
                    한약재로 활용되던 광물 속의 미네랄을 정제한 미네랄약침은
                    <br />
                    신경에 직접적으로 영양을 공급하고 프롤로 효과를 동반하기 때문에
                    <br />
                    근골격계 치료에 특화된 치료법입니다.
                  </>
                }
              />
            </div>
          </motion.div>
        </HomeDifferenceSection>
        <HomeSection
          style={{
            marginBottom: 0,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="에스본한의원 호전율"
              paragraph={
                <>
                  97% 시술 정확도로
                  <br />
                  통증의 원인을 치료합니다
                </>
              }
            />
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              marginTop: '50px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                width: '33%',
                height: '33%',
              }}
              src="/images/home/graph1.png"
              alt=""
            />
            <img
              style={{
                width: '33%',
                height: '33%',
              }}
              src="/images/home/graph2.png"
              alt=""
            />
            <img
              style={{
                width: '33%',
                height: '33%',
              }}
              src="/images/home/graph3.png"
              alt=""
            />
          </motion.div>
        </HomeSection>
        {/* 후기 */}
        {/* <FullWidthHomeSection>
        <Card
          containerStyle={{
            maxWidth: '1180px',
            margin: '0 auto',
          }}
          titleStyle={{
            color: '#a9a9a9',
          }}
          title="에스본한의원 환자 후기"
          paragraph="환자분들의 진솔한 후기"
          paragraphStyle={{
            color: '#ffffff',
          }}
        />
        <div
          style={{
            position: 'relative',
            marginTop: '50px',
            marginLeft: 'calc((100% - 1180px) / 2)',
          }}
        >
          <Swiper
            slidesPerView={4.2}
            spaceBetween={20}
            loop={true}
            modules={[Pagination, Navigation]}
            style={{}}
            ref={reviewRef}
          >
            {Array.from({ length: 10 }).map((_, index) => (
              <SwiperSlide
                key={index}
                style={{
                  width: '400px',
                  height: '400px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: '#5A191E',
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translate(-100%, -50%)',
              zIndex: 100,
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#826144',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => {
              reviewRef.current?.swiper.slidePrev();
            }}
          >
            <img src="/icons/chevron-left.svg" alt="" />
          </button>
        </div>
      </FullWidthHomeSection> */}
        <FullWidthHomeSection
          style={{
            // background: '#ffffff',
            marginBottom: '200px',
          }}
        >
          <motion.div
            style={{
              display: 'flex',
              maxWidth: '1180px',
              margin: '0 auto',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              containerStyle={{}}
              title="에스본한의원 진료과목"
              paragraph="후회없는 치료를 약속드립니다"
            />
            <div
              style={{
                alignSelf: 'flex-end',
                display: 'flex',
                gap: '30px',
                marginLeft: '82px',
                height: '64px',
              }}
            >
              <button
                onClick={() => {
                  subjectsRef.current?.swiper.slidePrev();
                }}
                style={{
                  padding: '10px',
                }}
              >
                <img src="/icons/button-left-white.svg" alt="" />
              </button>
              <button
                onClick={() => {
                  subjectsRef.current?.swiper.slideNext();
                }}
                style={{
                  padding: '10px',
                }}
              >
                <img src="/icons/button-right-white.svg" alt="" />
              </button>
            </div>
          </motion.div>
          <motion.div
            style={{
              height: '500px',
              marginLeft: 'calc((100% - 1180px) / 2)',
              position: 'relative',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              loop={true}
              modules={[Pagination, Navigation]}
              style={{
                marginTop: '50px',
              }}
              // navigation={true}
              ref={subjectsRef}
            >
              {cases.map(({ description, image, title }) => (
                <SwiperSlide
                  key={title}
                  style={{
                    width: 'fit-content',
                  }}
                >
                  <div
                    style={{
                      width: '380px',
                      height: '700px',
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${image})`,
                        height: '470px',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <NotoSerif
                        style={{
                          marginTop: '50px',
                          marginBottom: '24px',
                        }}
                        size="large"
                      >
                        {title}
                      </NotoSerif>
                      <Namsan
                        style={{
                          lineHeight: '32px',
                          color: '#D9D9D9',
                        }}
                      >
                        {description}
                      </Namsan>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
        </FullWidthHomeSection>
        <FullWidthImageDescription $bgImgUrl={'/images/home/image4.png'}>
          <motion.div
            style={{
              maxWidth: '950px',
              aspectRatio: '950 / 700',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                width: '100%',
                height: '100%',
              }}
              src="/images/home/image4.png"
              alt=""
            />
          </motion.div>
          <motion.div
            style={{
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '100px',
              justifyContent: 'center',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              titleStyle={{
                color: '#d9d9d9',
              }}
              title="에스본한의원 진료 철학"
              paragraphStyle={{
                color: '#ffffff',
              }}
              paragraph={
                <>
                  <NotoSerif
                    style={{
                      color: '#D2A780',
                      lineHeight: '64px',
                    }}
                    size="xlarge"
                  >
                    척추관절 치료
                  </NotoSerif>
                  를 중점적으로
                  <br />
                  하는 이유는 간단합니다
                </>
              }
            />
            <Pretendard
              as="p"
              style={{
                marginTop: '60px',
                lineHeight: 1.6,
                color: '#A9A9A9',
              }}
            >
              통증에서 빠르게 벗어나고자 수술 치료를 고려하시는 분들이 많이 계십니다.
              <br />
              그러나 짧지 않은 수술 과정과 수술로 인한 후유증, 회복까지의 과정이 결코
              <br />
              만만치 않기 때문에 쉽게 결정하지 못하는 경우가 많아요.
              <br />
              <br />
              수술할 여건이 되지 않거나 연세가 있으신 고령 환자분들에겐 수술 치료가
              <br />
              더욱 부담으로 다가오기 마련이죠.
            </Pretendard>
            <br />
            <Pretendard
              as="p"
              style={{
                lineHeight: 1.6,
                color: '#ffffff',
              }}
            >
              제가 척추관절 치료를 중점적으로 다루는 이유는 간단합니다.
              <br />
              수술을 원하지 않는 분들에게 절실한 희망이 되어드리고 싶었기 때문입니다.
              <br />
              <br />
              훌륭한 한의사분들이 많이 계시지만 적어도 척추관절 치료 분야에서는
              <br />
              국내에서 최고가 되고자 늘 다짐하고 노력하고 있습니다.
            </Pretendard>
          </motion.div>
        </FullWidthImageDescription>
        <FullWidthHomeSection
          style={{
            display: 'flex',
            // background: '#ffffff',
            paddingBottom: 0,
            paddingTop: '200px',
          }}
        >
          <motion.div
            style={{
              maxWidth: '950px',
              aspectRatio: '950 / 700',
              width: '100%',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Swiper
              modules={[Thumbs]}
              spaceBetween={10}
              ref={facilityRef}
              thumbs={{
                swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              style={{
                width: '100%',
                height: '100%',
              }}
              onSlideChange={(index: any) => {
                setCurrentFacilityIndex(index.activeIndex);
              }}
            >
              {facilities.map((facility, index) => (
                <SwiperSlide key={index}>
                  <ThumbImage src={facility} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              margin: '74px 0 74px 100px',
              flex: 1,
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              titleStyle={{
                color: '#a9a9a9',
              }}
              title="에스본한의원 둘러보기"
              paragraph={'척추관절 건강의 첫걸음'}
            />
            <Namsan
              as="p"
              style={{
                marginTop: '85px',
                color: '#d9d9d9',
                lineHeight: '33px',
              }}
            >
              에스본한의원은 방문해 주시는 모든 환자분들께서
              <br />
              건강한 몸과 마음으로 일상생활로 복귀하실 수 있도록
              <br />
              아낌없는 노력으로 치료하고 있습니다.
            </Namsan>
            <div
              style={{
                height: '150px',
                marginTop: '50px',
                marginBottom: '74px',
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-end',
                  display: 'flex',
                  gap: '30px',
                  height: '64px',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={() => {
                    facilityRef.current?.swiper.slidePrev();
                  }}
                  style={{
                    padding: '10px',
                  }}
                >
                  <img src="/icons/button-left-white.svg" alt="" />
                </button>
                <div>
                  <span style={{
                      color: '#ffffff',
                      fontSize: '22px',
                      fontWeight: 500
                    }}
                  >
                    {(currentFacilityIndex + 1).toString().padStart(2, '0')}
                  </span>
                  <span style={{
                      color: '#555555',
                      fontSize: '22px',
                      fontWeight: 500
                    }}
                  >
                  {' '}/{' '}{facilities.length.toString().padStart(2, '0')}
                  </span>
                </div>
                <button
                  onClick={() => {
                    facilityRef.current?.swiper.slideNext();
                  }}
                  style={{
                    padding: '10px',
                  }}
                >
                  <img src="/icons/button-right-white.svg" alt="" />
                </button>
              </div>
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                }}
              >
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={'auto'}
                  modules={[Thumbs]}
                >
                  {facilities.map((facility, index) => (
                    <ThumbSlide key={index}>
                      <ThumbImage src={facility} alt="" />
                    </ThumbSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </motion.div>
        </FullWidthHomeSection>

        <HomeSection
          style={{
            display: 'flex',
          }}
        >
          <motion.div
            style={{
              width: '500px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img
              style={{
                width: '100%',
                maxWidth: '500px',
                maxHeight: '450px',
                aspectRatio: '500 / 450',
                boxShadow: '2px 2px 15px 0px #00000026',
              }}
              src="/images/home/map.jpg"
              alt=""
            />
            <Map />
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '100px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card title="365일 척추관절 중점진료 에스본한의원" paragraph="에스본한의원 오시는 길" />

            <table
              style={{
                marginTop: '68px',
              }}
            >
              <tbody>
                <HomeTableRow
                  style={{
                    height: '60px',
                  }}
                >
                  <td
                    style={{
                      paddingRight: '50px',
                    }}
                  >
                    <Namsan
                      style={{
                        color: '#D2A780',
                      }}
                    >상담전화</Namsan>
                  </td>
                  <td
                    style={{
                      fontSize: '24px',
                      fontFamily: 'Roboto',
                      color: '#ffffff',
                    }}
                  >
                    031 - 357 - 1897
                  </td>
                </HomeTableRow>
                <HomeTableRow
                  style={{
                    height: '110px',
                    borderBottom: '1px solid #5d5d5d',
                  }}
                >
                  <td>
                    <Namsan
                      style={{
                        color: '#D2A780',
                      }}
                    >진료시간</Namsan>
                  </td>
                  <td className="flex flex-col font-light gap-[10px]">
                    <div
                      style={{
                        fontWeight: 300,
                        display: 'flex',
                        marginBottom: '14px',
                      }}
                    >
                      <div>
                        <Namsan
                          style={{
                            marginRight: '27px',
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          월 - 금
                        </Namsan>
                      </div>
                      <div>
                        <Namsan
                          style={{
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          오전 9시 50분 ~ 오후 8시
                        </Namsan>
                        <br />
                        <Namsan
                          style={{
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          (오후 1시 ~ 오후 2시 휴게시간)
                        </Namsan>
                      </div>
                    </div>
                    <div
                      style={{
                        fontWeight: 300,
                        display: 'flex',
                        marginBottom: '14px',
                      }}
                    >
                      <Namsan
                        style={{
                          marginRight: '27px',
                          color: '#ffffff',
                          lineHeight: '33px',
                        }}
                      >
                        토 - 일
                      </Namsan>
                      <div>
                        <Namsan
                          style={{
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          오전 9시 40분 ~ 오후 3시
                        </Namsan>
                        <br />
                        <Namsan
                          style={{
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          (휴게시간 없음)
                        </Namsan>
                      </div>
                    </div>
                    <div
                      style={{
                        fontWeight: 300,
                        display: 'flex',
                        marginBottom: '50px',
                      }}
                    >
                      <Namsan
                        style={{
                          marginRight: '27px',
                          color: '#ffffff',
                          lineHeight: '33px',
                        }}
                      >
                        공휴일
                      </Namsan>
                      <div>
                        <Namsan
                          style={{
                            color: '#ffffff',
                            lineHeight: '33px',
                          }}
                        >
                          운영
                        </Namsan>
                      </div>
                    </div>
                  </td>
                </HomeTableRow>
                <HomeTableRow
                  style={{
                    height: '60px',
                  }}
                >
                  <td>
                    <Namsan
                      style={{
                        color: '#D2A780',
                        display: 'block',
                        marginTop: '50px',
                      }}
                    >
                      대중교통
                    </Namsan>
                  </td>
                  <td
                    style={{
                      fontWeight: 300,
                    }}
                  >
                    <div>
                      <Namsan
                        style={{
                          color: '#ffffff',
                          display: 'inline-block',
                          width: '130px',
                        }}
                      >
                        금강펜테리움 :
                      </Namsan>
                      <Namsan
                        style={{
                          color: '#ffffff',
                          lineHeight: '33px',
                        }}
                      >
                        10, 13, 50-8, 99-1 하차 후 도보 7분
                      </Namsan>
                    </div>
                    <div
                      style={{
                        marginBottom: '30px',
                      }}
                    >
                      <Namsan
                        style={{
                          color: '#ffffff',
                          lineHeight: '33px',
                          display: 'inline-block',
                          width: '220px',
                        }}
                      >
                        반도유보라 / 아이비파크 :
                      </Namsan>
                      <Namsan
                        style={{
                          color: '#ffffff',
                          lineHeight: '33px',
                        }}
                      >
                        22-2 하차 후 도보 3분
                      </Namsan>
                    </div>
                  </td>
                </HomeTableRow>
                <HomeTableRow>
                  <td>
                    <Namsan
                      style={{
                        color: '#D2A780',
                      }}
                    >
                      주차안내
                    </Namsan>
                  </td>
                  <td
                    style={{
                      fontWeight: 300,
                    }}
                  >
                    <Namsan
                      style={{
                        color: '#ffffff',
                        lineHeight: '33px',
                      }}
                    >
                      건물 뒤편 메가타워 주차장 (이차돌 옆) - 2시간 무료
                      <br />
                      만차 시 수노을 공원 주차장 - 6시간 무료
                    </Namsan>
                  </td>
                </HomeTableRow>
              </tbody>
            </table>
            <Button
              hasRightArrow={true}
              style={{
                width: '178px',
                marginTop: '70px',
              }}
            >
              <Link to={reservationLink} target="_blank">
                <Namsan
                  style={{
                    fontSize: '18px',
                    color: '#ffffff',
                  }}
                >
                  진료 예약하기
                </Namsan>
              </Link>
            </Button>
          </motion.div>
        </HomeSection>
        <HomeSection
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card title="에스본한의원 FAQ" paragraph="대표 원장이 답변드립니다" position="center" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
          >
            <FaqContainer
              style={{
                marginTop: '50px',
                backgroundColor: '#1B2E40',
              }}
            >
              {faq.map(({ question, answer, initialOpen }) => (
                <FaqListItem key={question}>
                  <Accordion
                    header={
                      <FaqListItemHeader>
                        <NotoSerif
                          style={{
                            flex: 1,
                          }}
                        >
                          {question}
                        </NotoSerif>

                        <div
                          style={{
                            width: '42px',
                            height: '42px',
                            backgroundColor: '#EBEBEB',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <svg
                            width="9"
                            height="5"
                            viewBox="0 0 9 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              width: '12px',
                              height: '12px',
                            }}
                          >
                            <path
                              d="M8.14258 4.57153L4.57103 0.999988L0.999488 4.57153"
                              stroke="#1B2E40"
                            />
                          </svg>
                        </div>
                      </FaqListItemHeader>
                    }
                    body={
                      <Namsan
                        as="p"
                        style={{
                          lineHeight: '36px',
                          paddingLeft: '31px',
                          paddingBottom: '50px',
                          color: '#d9d9d9',
                        }}
                      >
                        {answer}
                      </Namsan>
                    }
                    initialOpen={initialOpen}
                  />
                </FaqListItem>
              ))}
            </FaqContainer>
          </motion.div>
        </HomeSection>
      </HomeContainer>
    </>
  );
};

export default HomePage;
