import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan, NotoSerif, Pretendard } from '../../../components/Typography';
import { DoctorContainer, DoctorSection, MobileDoctorContainer } from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { motion } from 'framer-motion';

// const length = 3;
const mobileImageList = [
  ['1-1.svg', '1-2.png'],
  ['2-1.svg', '2-2.png', '2-3.svg'],
  ['4-1.png', '4-2.svg'],
  ['3-2.png', '3-3.svg'],
  // ['3-1.svg', '3-2.png', '3-3.svg'],
];

const AboutDoctorPage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 의료진 소개</title>
        </Helmet>
        <PageTitle title="의료진 소개" bgImage="about" />

        <MobileDoctorContainer>
          {mobileImageList.map((arr, index) => (
            <div key={index}>
              {arr.map((image) => (
                <img
                  style={{
                    width: '100%',
                  }}
                  key={image}
                  src={`/responsive/about/doctor/${image}`}
                  alt=""
                />
              ))}
            </div>
          ))}
        </MobileDoctorContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 의료진 소개</title>
      </Helmet>
      <PageTitle title="의료진 소개" bgImage="about" />
      <DoctorContainer>

        <DoctorSection>
          
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h2'}
              paragraph="내 가족을 소개해 줄 수 있는 한의원"
              paragraphAs="p"
              position="center"
            />
          </motion.div>
          <motion.div
            style={{
              width: '100%',
              marginTop: '50px',
            }}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <img  width={'100%'}
              src="/images/about/doctor/image1.png"
              alt="365일 척추관절 중점진료 에스본한의원"
            />
          </motion.div>
          <motion.div
            style={{
              width: '100%',
              marginTop: '200px',
            }}
          >

            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h2'}
              paragraphAs="p"
              position="center"
            />
            {/* <Card
              title="365일 척추관절 중점진료 에스본한의원"
              titleAs={'h2'}
              style={{ textAlign: 'center' }}
            ></Card> */}
          </motion.div>
          
        </DoctorSection>
        
        <DoctorSection style={{ 
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap:'21px'
            }}
        >
          
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              src="/images/about/doctor/representative_doctor.png"
              alt="강인혜"
              containerStyle={{
                gap: '30px',
                flexDirection: 'column',
              }}
              // descriptionStyle={{
              //   width: '280px',
              // }}
              description={
                <div
                  style={{
                    width: '310px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    lineHeight: '33px',
                  }}
                >
                  <Namsan
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    한의사
                  </Namsan>
                  <NotoSerif
                    size="large"
                    $isPrimary
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                      marginBottom: '20px',
                    }}
                  >
                    강인혜
                  </NotoSerif>
                  <Namsan>
                    미국 근골격계 초음파사(RMSK)<br />
                    ARDMS 미국 진단 초음파협회 회원<br />
                    국제모유수유전문가(IBCLC)<br />
                    동국대학교 석박사 통합과정<br />
                    원광대학교 한의학과 졸업<br />
                  </Namsan>
                  <Namsan>
                    대한침도의학회 정회원
                  </Namsan>
                  <Namsan>
                    現)에스본한의원 진료원장<br />
                    前)식품의약품안전처 연구원<br />
                    前)함소아한의원 평촌점 원장<br />
                    前)광덕안정한의원 구로디지털점 원장<br />
                  </Namsan>
                </div>
              }
              imageStyle={{
                width: '379px',
                borderRadius: '24px',
              }}
            />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              src="/images/about/doctor/man_doctor.png"
              alt="김승주"
              containerStyle={{
                gap: '30px',
                flexDirection: 'column',
                
              }}
              description={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    lineHeight: '33px',
                  }}
                >
                  <Namsan
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    한의사
                  </Namsan>
                  <NotoSerif
                    size="large"
                    $isPrimary
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                      marginBottom: '20px',
                    }}
                  >
                    김승주
                  </NotoSerif>
                  <Namsan>
                    미국 근골격계 초음파사(RMSK) <br />
                    ARDMS 미국 진단 초음파협회 회원 <br />
                    식약처장 인증 <br />
                    마약류 예방·재활 전문인력 인증제 사회재활상담사 <br />
                    원광대학교 한의학과 졸업 <br />
                  </Namsan>
                  <Namsan>
                    대한한의학회 정회원 <br />
                    척추신경추나의학회 회원 <br />
                  </Namsan>
                  <Namsan>
                    現)에스본한의원 진료원장 <br /> 
                    前)사랑인한의원 진료원장 <br /> 
                    前)천북보건지소 한의과 <br /> 
                    前)청소보건지소 한의과 <br />
                  </Namsan>
                </div>
              }
              imageStyle={{
                width: '379px',
                borderRadius: '24px',
              }}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              delay: 0.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <ImageDescriptionBox
              src="/images/about/doctor/general_doctor.png"
              alt="박정민"
              containerStyle={{
                gap: '30px',
                flexDirection: 'column',
              }}
              description={
                <div
                  style={{
                    width: '310px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    lineHeight: '33px',
                  }}
                >
                  <Namsan
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    한의사
                  </Namsan>
                  <NotoSerif
                    size="large"
                    $isPrimary
                    style={{
                      color: '#D2A780',
                      fontWeight: 300,
                      marginBottom: '20px',
                    }}
                  >
                    박정민
                  </NotoSerif>
                  <Namsan>
                    동국대학교 한의학과 졸업 <br />
                  </Namsan>
                  <Namsan>
                    대한침도의학회 회원 <br />
                    한방비만학회 회원 <br />
                    대한약침학회 회원 <br />
                    대한융합한의학회 회원 <br />
                  </Namsan>
                  <Namsan>
                    現)에스본한의원 진료원장 <br />
                  </Namsan>
                </div>
              }
              imageStyle={{
                width: '379px',
                borderRadius: '24px',
              }}
            />
          </motion.div>


        </DoctorSection>

        
        
      </DoctorContainer>
    </>
  );
};

export default AboutDoctorPage;
