import { Helmet } from 'react-helmet';
import Card from '../../../components/Card';
import { HorizontalDivider } from '../../../components/Divider';
import ImageDescriptionBox from '../../../components/ImageDescriptionBox';
import PageTitle from '../../../components/PageTitle';
import { Namsan } from '../../../components/Typography';
import { DifferenceContainer, DifferenceSection, MobileDifferenceContainer } from './styles';
import useWindowSize from '../../../hooks/useWindowSize';
import { motion } from 'framer-motion';

const differences = [
  {
    title: (
      <div style={{
          color: '#D2A780',
          fontWeight: 200,
        }}
      >
        척추관절 특화 치료,
        <br />
        초음파 유도하 미네랄약침
      </div>
    ),
    alt: '미네랄 약침',
    content: (
      <>
        <Namsan
          style={{
            marginBottom: '20px',
            display: 'block',
            color: '#d9d9d9',
          }}
        >
          초음파 기기를 활용하여 통증의 원인을 정확하게
          <br />
          확인한 이후 실시간으로 화면을 보며 감작된 신경을 치료합니다.
        </Namsan>
        <Namsan
          style={{
            color: '#d9d9d9',
          }}
        >
          미네랄약침의 성분이 신경에 직접적으로 영양을
          <br />
          공급하여 보다 빠르게 회복을 촉진합니다.
        </Namsan>
      </>
    ),
  },
  {
    title: (
      <div style={{
          color: '#D2A780',
          fontWeight: 200,
        }}
      >
        국제 초음파 자격
        <br />
        (RMSK) 보유
      </div>
    ),
    content: (
      <>
        <Namsan
          style={{
            marginBottom: '20px',
            display: 'block',
            color: '#d9d9d9',
          }}
        >
          RMSK자격증은 세계에서 가장 공신력 있는 자격증 중 하나로
          <br />
          평가받고 있으며, 미국 의사 인증 및 진흥협회인 APCA에서
          <br />
          주최하는 시험을 합격해야 받을 수 있는 자격증입니다.
        </Namsan>
        <Namsan
          style={{
            color: '#d9d9d9',
          }}
        >
          RMSK 자격증을 취득하는 과정에서 초음파 스캔, 병리학,
          <br />
          근골격계 해부학 등 다방면으로 전문지식을 습득하였습니다.
          <br />
          현재는 주기적으로 초음파 유도하 약침술 강연을 진행 중입니다.
        </Namsan>
      </>
    ),
  },
  {
    title: (
      <div style={{
          color: '#D2A780',
          fontWeight: 200,
        }}
      >
        디스크 치료 및 협착증
        <br />
        예방을 위한 무중력 감압기
      </div>
    ),
    content: (
      <>
        <Namsan
          style={{
            marginBottom: '20px',
            display: 'block',
            color: '#d9d9d9',
          }}
        >
          단계적이고 전문적인 비수술적 척추 감압교정치료 기기인
          <br />
          무중력 감압기 MID ROBO MAX를 보유하고 있습니다.
        </Namsan>
        <Namsan
          style={{
            color: '#d9d9d9',
          }}
        >
          디스크의 퇴행성 변화로 인해 발생하는 교정 및 치료에
          <br />
          효과적이며 척추관협착증을 사전에 예방할 수 있습니다.
        </Namsan>
      </>
    ),
  },
  {
    title: (
      <div style={{
          color: '#D2A780',
          fontWeight: 200,
        }}
      >
        식품의약품 연구원 출신 대표원장,
        <br />
        체계적인 다이어트 클리닉
      </div>
    ),
    content: (
      <>
        <Namsan
          style={{
            marginBottom: '20px',
            display: 'block',
            color: '#d9d9d9',
          }}
        >
          간수치를 비롯한 환자의 현재 건강상태를 꼼꼼히 체크한 뒤
          <br />
          인바디 검사를 바탕으로 체계적인 다이어트 계획을 수립합니다.
        </Namsan>
        <Namsan
          style={{
            color: '#d9d9d9',
          }}
        >
          한 달 평균 약 4kg 체중 감량이 가능하며 쿨세라, 노블쉐이프
          <br />
          등의 시술을 통해 다이어트 효과를 극대화 합니다.
        </Namsan>
      </>
    ),
  },
];

const mobileImageList = [
  ['1-1.svg', '1-2.png', '1-3.svg'],
  ['2-1.png', '2-2.svg'],
  ['3-1.png', '3-2.svg'],
  ['4-1.png', '4-2.svg'],
];

const AboutDifferencePage = () => {
  const { width } = useWindowSize();
  if ((width || window.innerWidth) < 1080) {
    return (
      <>
        <Helmet>
          <title>에스본한의원 | 차별점</title>
        </Helmet>
        <PageTitle title="차별점" bgImage="about" />

        <MobileDifferenceContainer>
          {mobileImageList.map((arr, index) => (
            <div key={index}>
              {arr.map((image) => (
                <img
                  key={image}
                  style={{
                    width: '100%',
                  }}
                  src={`/responsive/about/difference/${image}`}
                  alt=""
                />
              ))}
            </div>
          ))}
        </MobileDifferenceContainer>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>에스본한의원 | 차별점</title>
      </Helmet>
      <PageTitle title="차별점" bgImage="about" />
      <DifferenceContainer>
        <DifferenceSection>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <Card
              title="365일 척추관절 중점진료 에스본한의원"
              paragraph={`에스본한의원의 특별함 ${differences.length}가지`}
              position="center"
            />
          </motion.div>
        </DifferenceSection>
        {differences.map((difference, index) => (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 1.3,
              y: {
                duration: 0.3,
              },
            }}
            viewport={{
              once: true,
            }}
          >
            <DifferenceSection key={index}>
              <ImageDescriptionBox
                //src={`/images/about/difference/image${index + 1}.png`}
                src={
                      index === 3 
                        ? '/images/medicine/diet/image3.png' 
                        : `/images/about/difference/image${index + 1}.png`
                    }                
                                
                alt={difference.alt}
                containerStyle={{
                  gap: '100px',
                  marginTop: '100px',
                }}
                imageStyle={{
                  maxWidth: '580px',
                  width: '100%',
                }}
                description={
                  <div style={{ lineHeight: '33px' }}>
                    <Card
                      title={`에스본한의원 차별점 0${index + 1}`}
                      titleAs="h2"
                      paragraph={difference.title}
                      paragraphStyle={{
                        color: '#826144',
                        fontSize: '28px',
                        lineHeight: '1.6',
                        fontWeight: 500,
                      }}
                      position="left"
                    />
                    <HorizontalDivider
                      style={{
                        marginTop: '50px',
                        marginBottom: '50px',
                      }}
                    />
                    <div>{difference.content}</div>
                  </div>
                }
                descriptionStyle={{
                  flexGrow: 1,
                  flexShrink: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              />
            </DifferenceSection>
          </motion.div>
        ))}
      </DifferenceContainer>
    </>
  );
};

export default AboutDifferencePage;
