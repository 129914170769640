import styled from 'styled-components';

export const DoctorContainer = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const DoctorSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileDoctorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding: 0 16px;
  margin: 100px 0;
`;

