export const faq = [
  {
    question: '치료 후 체내에 미네랄약침 성분이 사라지면 다시 치료를 받아야 되나요?',
    answer: (
      <>
        미네랄약침에 함유된 미네랄 성분은 신경의 압박을 해소하는 것 뿐만 아니라 
        <br />
        말초신경에 직접적으로 영양을 공급합니다.
        <br />
        신경병변 인근 조직의 재생과 회복속도를 높여주기 때문에 체내에 성분이 사라져도 
        <br />
        꼭 다시 치료를 받아야 하는 것은 아닙니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: '미네랄약침 부작용은 없나요?',
    answer: (
      <>
      미네랄 약침은 내분비성 부작용이 없는 약물이며, 통증의 원인이 되는
      <br />
      신경압박 및 신경손상을 해소하는 한의학 치료입니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: '디스크 치료 시 미네랄약침 치료를 몇 번 받아야 되나요?',
    answer: (
      <>
        목디스크는 2~4주, 허리디스크는 4~6주의 기간이면 뚜렷한 개선을 기대할 수 있습니다.
        <br />
        하지만 이는 기저질환이 없는 평균적인 체력을 가진 성인 기준이며, 개인의 체력과 컨디션에
        <br />
        따라 차이가 존재할 수 있습니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: '치료 예후를 초음파로 직접 확인할 수 있나요?',
    answer: (
      <>
        진단 시 초음파를 함께 확인하며 환자분께서 충분히 이해하실 수 있도록 자세히 설명을 드립니다.
        <br />
        또한, 치료가 되어가는 과정을 육안으로 직접 확인하실 수 있습니다.
        </>
        
    ),
    initialOpen: false,
  },
];

export const mobileFaq = [
  {
    question: (
      <>
        치료 후 체내에 미네랄약침 성분이
        <br />
        사라지면 다시 치료를 받아야 되나요?
      </>
    ),
    answer: (
      <>
        미네랄약침에 함유된 미네랄 성분은 신경의 압박을
        <br />
        해소하는 것 뿐만 아니라 말초신경에 직접적으로 <br />
        영양을 공급합니다.
        <br />
        <br />
        신경병변 인근 조직의 재생과 회복속도를 높여주기 <br />
        때문에 체내에 성분이 사라져도 꼭 다시 치료를
        <br />
        받아야 하는 것은 아닙니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: '미네랄약침 부작용은 없나요?',
    answer: (
      <>
      미네랄 약침은 내분비성 부작용이 없는 약물이며, 통증의 원인이 되는
      <br />
      신경압박 및 신경손상을 해소하는 한의학 치료입니다.
    </>
    ),
    initialOpen: false,
  },
  {
    question: (
      <>
        디스크 치료 시 미네랄약침 <br />
        치료를 몇 번 받아야 되나요?
      </>
    ),

    answer: (
      <>
        목디스크는 2~4주, 허리디스크는 4~6주의 <br />
        기간이면 뚜렷한 개선을 기대할 수 있습니다.
        <br />
        <br />
        하지만 이는 기저질환이 없는 평균적인 체력을
        <br />
        가진 성인 기준이며, 개인의 체력과 컨디션에
        <br />
        따라 차이가 존재할 수 있습니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: (
      <>
        치료 예후를 초음파로
        <br />
        직접 확인할 수 있나요?
      </>
    ),
    answer: (
      <>
        진단 시 초음파를 함께 확인하며 환자분께서
        <br />
        충분히 이해하실 수 있도록 자세히 설명을
        <br /> 드립니다.
        <br />
        <br />
        또한, 치료가 되어가는 과정을 육안으로 직접
        <br />
        확인하실 수 있습니다.
      </>
    ),
    initialOpen: false,
  },
];

export const cases = [
  {
    image: '/images/home/cases/image1.svg',
    title: '근골격계 질환',
    description: (
      <>
        디스크, 협착증, 관절염, 오십견 등의 퇴행성
        <br />
        질환과 염좌, 엘보우, 석회성건염, 근육 파열
        <br />
        등의 근골격계 질환을 치료합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image2.svg',
    title: '교통사고후유증',

    description: (
      <>
        교통사고 이후 발생하는 다양한
        <br />
        신체적, 정신적 문제를 해결합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image3.svg',
    title: '스포츠 손상',
    description: (
      <>
        러닝, 테니스, 자전거 등 스포츠 활동 후<br />
        발생하는 스포츠 손상을 치료합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image4.svg',
    title: '산재후유증',
    description: (
      <>
        업무로 인한 부상 혹은 질병으로 피해가
        <br />
        발생한 산업재해 문제를 치료합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image5.svg',
    title: '여성 질환',
    description: (
      <>
        생리통, 산후풍, 불임, 난임 등<br />
        여성 질환 문제를 치료합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image6.svg',
    title: '두통 및 어지럼증',
    description: (
      <>
        대후두신경, 제3후두신경으로 인한
        <br />
        두통 및 어지럼증 문제를 치료합니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image7.svg',
    title: '체중 감량 문제',
    description: (
      <>
        인바디 기기와 쿨쎄라, 노블쉐이프를 통해
        <br />
        이상적인 체중에 도달할 수 있도록 도와드립니다
      </>
    ),
  },
  {
    image: '/images/home/cases/image8.svg',
    title: '만성 건강 문제',
    description: (
      <>
        만성피로, 만성두통, 갱년기, 빈혈 등<br />
        만성 건강 문제를 치료합니다
      </>
    ),
  },
];

export const facilities = [
  '/images/home/facilities/image1.jpg',
  '/images/home/facilities/image2.jpg',
  '/images/home/facilities/image3.jpg',
  '/images/home/facilities/image4.jpg',
  '/images/home/facilities/image5.jpg',
  '/images/home/facilities/image6.jpg',
  '/images/home/facilities/image7.jpg',
  '/images/home/facilities/image8.jpg',
];
