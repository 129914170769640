import { MobileImageData } from '../../types/types';

export const afterEffectsList = [
  {
    title: '교통사고 후유증',
    subtitle: '교통사고 이후 발생하는 신체적, 정신적 문제를 치료합니다',
    bottomTitle: (
      <>
        교통사고
        <br />
        후유증
      </>
    ),
    content: (
      <>
        편두통 / 어지럼증 / 외상 후 스트레스 / 불면증
        <br />
        목디스크 / 거북목 / 일자목 / 어깨 근육통 / 어깨 결림
        <br />
        허리디스크 / 허리염좌 / 좌골신경통
        <br />
        다리저림 / 하지불안증후군 / 무릎인대손상 / 무릎통증
      </>
    ),
  },
  {
    title: '척추관절 수술 후 재활',
    subtitle: '척추관절 수술 치료를 받으신 분들의 회복 속도를 높입니다',
    bottomTitle: (
      <>
        척추관절
        <br />
        수술 후 재활
      </>
    ),
    content: (
      <>
        디스크 / 협착증 / 골절 / 무릎 및 어깨 수술 등
        <br />
        척추관절 수술 이후 줄어든 관절 가동범위와 손상된 조직을
        <br />
        치료하고 면역력을 높여 척추관절의 회복을 촉진합니다
      </>
    ),
  },
  {
    title: '자궁 수술 후 재활',
    subtitle: '자궁 수술 치료를 받으신 분들의 회복 속도를 높입니다',
    bottomTitle: (
      <>
        자궁
        <br />
        수술 후 재활
      </>
    ),
    content: (
      <>
        자궁내막증, 자궁선근종, 자궁근종, 자궁경부암 등
        <br />
        자궁 수술 후 발생하는 부종과 손상된 조직을
        <br />
        치료하고 면역력을 높여 자궁의 회복을 촉진합니다
      </>
    ),
  },
];

export const differencesList = [
  {
    image: '/images/hospitalization/list/image1.png',
    title: '개인 TV (OTT 시청 가능)',
  },
  {
    image: '/images/hospitalization/list/image2.png',
    title: '개인 안마기기',
  },
  {
    image: '/images/hospitalization/list/image3.png',
    title: '개인 세안대',
  },
  {
    image: '/images/hospitalization/list/image4.png',
    title: '기본 어메니티 제공',
  },
  {
    image: '/images/hospitalization/list/image5.png',
    title: '건강한 저염식 식단',
  },
  {
    image: '/images/hospitalization/list/image6.png',
    title: '냉난방 시스템',
  },
];

export const faq = [
  {
    question: <>자동차 보험으로 진료를 받으려면 어떻게 해야 되나요?</>,
    answer: (
      <>
        환자분께서 대인사고 접수번호와 보험회사명만 한의원에 알려주시면
        <br />
        바로 접수 및 진행됩니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>교통사고 후 시간이 조금 지난 후에도 진료를 받을 수 있나요?</>,
    answer: (
      <>
        개인사정으로 치료를 제때 받지 못했을 때 보험사와 합의가 완전하게
        <br />
        이루어지지 않은 상태라면 자동차 보험으로 진료가 가능합니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>자동차 보험으로 치료를 받을 때 치료비는 어떻게 되나요?</>,
    answer: (
      <>
        자동차 보험 의료혜택은 1999년부터 한방치료에 적용되기 때문에
        <br />
        본인부담금이 0원으로 통원치료는 물론 입원치료까지 혜택을 받으실 수 있습니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>다른 병원에 입원 중인데 옮길 수 있나요?</>,
    answer: (
      <>
        사고일로부터 3일이 경과되지 않았다면 가능합니다.
        <br />
        만약 3일이 지났다면 저희 한의원으로 직접 연락주셔서
        <br />
        상담부탁드립니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>입원을 하게 되면 어떤 치료를 받게 되나요?</>,
    answer: (
      <>
        CT, MRI 검사 시 이상 소견이 발견되면 경우에 따라 초음파 약침치료를
        <br />
        포함하여 추나치료, 봉침치료, 약침치료, 부항, 한방물리요법, 온열치료,
        <br /> 
        한약 등의 한방치료를 환자분의 건강상태에 맞춰 하루 2회 받게 됩니다.
      </>
    ),
    initialOpen: false,
  },
];
export const mobileFaq = [
  {
    question: (
      <>
        자동차 보험으로 진료를 받으려면
        <br /> 어떻게 해야 되나요?
      </>
    ),
    answer: (
      <>
        환자분께서 대인사고 접수번호와 보험회사명만
        <br />
        한의원에 알려주시면 바로 접수 및 진행됩니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: (
      <>
        교통사고 후 시간이 조금 지난 후에도 <br />
        진료를 받을 수 있나요?
      </>
    ),
    answer: (
      <>
        개인사정으로 치료를 제때 받지 못했을 때<br />
        보험사와 합의가 완전하게 이루어지지 않은
        <br />
        상태라면 자동차 보험으로 진료가 가능합니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: (
      <>
        자동차 보험으로 치료를 받을 때 <br />
        치료비는 어떻게 되나요?
      </>
    ),
    answer: (
      <>
        자동차 보험 의료혜택은 1999년부터 한방치료에
        <br />
        적용되기 때문에 본인부담금이 0원으로 통원치료는
        <br />
        물론 입원치료까지 혜택을 받으실 수 있습니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>다른 병원에 입원 중인데 옮길 수 있나요?</>,
    answer: (
      <>
        사고일로부터 3일이 경과되지 않았다면 가능합니다.<br />
        만약 3일이 지났다면 저희 한의원으로 직접 연락주셔서 상담부탁드립니다.
      </>
    ),
    initialOpen: false,
  },
  {
    question: <>입원을 하게 되면 어떤 치료를 받게 되나요?</>,
    answer: (
      <>
        CT, MRI 검사 시 이상 소견이 발견되면 경우에 따라 초음파 약침치료를 포함하여 
        추나치료, 봉침치료, 약침치료, 부항, 한방물리요법, 온열치료, 한약 등의 
        한방치료를 환자분의 건강상태에 맞춰 하루 2회 받게 됩니다.
      </>
    ),
    initialOpen: false,
  },
];

export const tabList = ['접수과정', '입원 시', '통원 시'];

export const mobileImageList: MobileImageData[] = [
  {
    arr: ['1-1.svg', '1-2.png', '1-3.svg', '1-4.png', '1-5.svg', '1-6.png', '1-7.svg'],
  },
  {
    arr: ['3-1.svg'],
  },
  {
    arr: ['4-1.svg'],
  },
];
